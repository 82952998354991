import "intersection-observer"
import { PROD_SITE } from '@/const/prod.const';
import u from '@/util';
import { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import useConfBase from "@/hooks/useConfBase";
import { appendHost } from "@/const/append"


const regex = /(http|https):\/\//;

interface IImg {
    src: string;
    alt?: string;
    width?: number;
    height?: number;
    fill?: boolean;
    style?: CSSProperties,
    cls?: string;
    id?: string;
    ref?: any
    // 默认图
    errorImg?: 1 | 2 | 3,
    click?: () => void;
    errorCheck?: boolean;
    encryptUrl?: string;
    reload?: boolean; // 加密reload
    children?: any
}

const Img: FC<IImg> = ({ children, src, alt, width, height, fill, style, cls, id, errorImg, click, errorCheck, encryptUrl , reload}) => {
    const [config] = useConfBase();
    const { ref, inView, entry } = useInView({
        rootMargin: '0px',
        threshold: 0.5,
    });
    // console.log("testing image", imgDomain)
    const [error, setError] = useState<boolean>(false);
    const [imgSrc, setImgSrc] = useState<string>()

    useEffect(() => {
        if (src) {
            if(src.includes('http') && !src.includes(config.image_source_url) && !src.includes('.txt')  ){
                return setImgSrc(src);
            }

            let _src = src;

            const isBase64 = _src.indexOf("data:") >= 0;
            if (!isBase64 && !regex.test(_src) && src.includes('http')) {
                // console.log("this is the url append", config.image_source_url)
                _src = appendHost(_src, config.image_source_url); // Pass image_source_url to appendHost
                _src = _src.includes('.txt') ? _src : (_src+'.txt')
            }
            setImgSrc(_src);
        } else {
            const fetchData = async () => {
                const fetchAssetImg = await u.fetchAssetImg(appendHost(`/images/default/loading/750x422.jpg`, config.image_source_url));
                if(fetchAssetImg) return setImgSrc(fetchAssetImg);
            }
            fetchData()
        }
    }, [src, config.image_source_url])

    useEffect(() => {
        if (error) {
            let _v = "/images/default/loading/750x422.jpg";
            if (errorImg === 2) {
                _v = "/images/default/loading/790x530.jpg";
            }
            const newSrc = appendHost(_v, config.image_source_url);
            
            setImgSrc(newSrc.includes('.txt') ? newSrc : `${newSrc}.txt`);
        }
    }, [errorImg, error, src, config.image_source_url]);

    const errHandler = useCallback(() => {
        return setError(true)
    }, [])

    const decryptImg = useCallback(async () => {
        if (imgSrc && !imgSrc.includes("4AAQSkZJRgABAQAAAQABAAD") && !imgSrc.includes("R0lGODlhGgI6APf")) return;
    
        // Skip appending host if already contains "jm.wuxingruoyin.top"
        if (src && src.includes("jm.wuxingruoyin.top")) return src;
    
        let finalSrc = src;
    
        // Check if encryptUrl exists and needs processing
        if (encryptUrl) {
            if (encryptUrl && !encryptUrl.includes(config.image_source_url) && !encryptUrl.startsWith("http")) {
                finalSrc = appendHost(encryptUrl, config.image_source_url);
            } else {
                finalSrc = encryptUrl;
            }

            // TEST: ENCRYPTURL
            let url = encryptUrl.replace(config.image_url+"/","").replaceAll('//', '/'); // image path
            // console.log('thisurl', url)
            if(url.includes('.txt')){
                url = url.substring(0, url.indexOf(".txt"));
            }
            if(url.charAt(0) !== '/'){
                url = "/"+url;
            }
            // console.log('after substring', url)
            let sourcurl = config.encrypted_image_domain ?? process.env.REACT_APP_STATIC_SOURCE_URL; // Change to the desired base URL
            const vidKeyParam = u.addImgKeyParam(url);
        
            const dynamicEncryptUrl = `${sourcurl}${url}${vidKeyParam}`;
            // console.log("Generated encrypt URL:", dynamicEncryptUrl);
            finalSrc = dynamicEncryptUrl;
            // TEST: ENCRYPTURL
        } else {
            if (src && !src.includes(config.image_source_url) && !src.startsWith("http")) {
                finalSrc = appendHost(src, config.image_source_url);
            }
        }
    
        // console.log("check the value", finalSrc);
    
        if (!finalSrc.includes('loading')) {
            console.log(`decryptImg==========> ${finalSrc}`);
        }

        let res;

        if (finalSrc.includes("wsSecret")) {
            return setImgSrc(finalSrc); // Just set finalSrc directly as imgSrc
            
        } else {
            res = await u.fetchData(finalSrc); // Proceed to fetch the data if no "wsSecret"

        }
    
        // console.log("correct resssss", res)
        if (res) {
            if (typeof res === "string" && res.includes("404 Not Found")) {
                const fetchAssetImg = await u.fetchAssetImg(appendHost('/images/default/loading/750x422.jpg', config.image_source_url));
                if (fetchAssetImg) return setImgSrc(fetchAssetImg);
            }
    
            let __decrypted = "";
            try {
                __decrypted = res.indexOf("data") >= 0 ? res : u.imgDecrypt(res);
            } catch {
                const fetchAssetImg = await u.fetchAssetImg(appendHost('/images/default/loading/750x422.jpg', config.image_source_url));
                if (fetchAssetImg) return setImgSrc(fetchAssetImg);
            }
            // console.log("decrypimg", __decrypted)
            return setImgSrc(__decrypted);
        } else {
            const fetchAssetImg = await u.fetchAssetImg(appendHost('/images/default/loading/750x422.jpg', config.image_source_url));
            if (fetchAssetImg) 
                return setImgSrc(fetchAssetImg);
        }


    }, [encryptUrl, imgSrc]);
    
    
    

    // const callDefaultImg = useCallback( async (url: any) => {
    //     if (url && url.indexOf("data") >= 0) return;
    //     const res = await u.fetchData(url);
    //     if (res) {
    //         let __decrypted = ""
    //         try {
    //             __decrypted = res.indexOf("data") >= 0 ? res : u.imgDecrypt(res);
    //         } catch {
    //         }

    //         if(__decrypted){
    //             return setImgSrc(__decrypted)
    //         }
    //     } 
    //     return;
    // }, [])

    useEffect(() => {
        if(`${src}`.includes('http') && !`${src}`.includes('https://image.utt39.com/maomi/mm_web_assets') && !`${src}`.includes('.txt') ){
            return setImgSrc(src);
        }

        if (`${imgSrc}`.includes("4AAQSkZJRgABAQAAAQABAAD") && inView) {
            decryptImg()
        }

        if (`${imgSrc}`.includes("R0lGODlhGgI6APf") && inView) {
            decryptImg()
        }

        if((!`${imgSrc}`.includes("data")) && `${src}`.includes('/images/')){
            decryptImg()
        }
    }, [encryptUrl, inView, decryptImg, imgSrc, src])
    
    // useEffect(() => {
    //     if (reload && inView) {
    //         if (encryptUrl) {
    //             decryptImg()
    //         }
    //     }
    // }, [reload, inView,])

    useEffect(() => {
        // if(`${src}`.includes('http') && !`${src}`.includes('https://image.utt39.com/maomi/mm_web_assets') && !`${src}`.includes('.txt') ){
        //     return setImgSrc(src);
        // }
        if(`${src}`.includes("jm.wuxingruoyin.top")) return;

        if (`${src}`.indexOf("data") < 0) {
            if(!`${encryptUrl}`.includes('loading')){
                console.log('first load =================>', src , encryptUrl, imgSrc)
            }

            const fetchData = async () => {
                const fetchAssetImg =  await u.fetchAssetImg(appendHost('/images/default/loading/750x422.jpg', config.image_source_url));
                if(fetchAssetImg) return setImgSrc(fetchAssetImg);
            }
            fetchData()
        }
    }, [encryptUrl, src])

    const actualImgEl = useMemo(() => {
        return <img ref={ref} onClick={click} id={id} onError={errHandler} style={style} sizes="100vh" className={`point fill ${cls}`} height={height || 0} width={width || 0} alt={alt || ''} src={imgSrc} />
    }, [ref, click, id, errHandler, style, cls, height, width, alt, imgSrc])
    // console.log("actual", imgSrc)

    return (
        <>
            {errorCheck ?
                error ? '' : actualImgEl
                : actualImgEl} 
            {children && children(imgSrc)}
        </>
    )
}

export default Img;