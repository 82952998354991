import { FC, useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react"
import styles from "@/components/styles/titleWithList.module.css"
import Img from "@/components/img";
import { appendHost } from "@/const/append";
import u, { ELocalKey, cancelAllPreviousFetchRequests } from "@/util";
import moment from "moment";
import Empty from "@/components/empty";
import useConfBase from "@/hooks/useConfBase";
import BuyTopicModal from "@/components/modal/buyTopicModal";
import { EModalTypes, modalSubject$ } from "@/hooks/useModal";
import useFlipFlop from "@/hooks/useFlipFlop";

interface IImageList {
    list: any[];
    idkey: string;
    idValue: string;
    imgUrlkey: string;
    channel: any;
    reset?: boolean;
    is300?: boolean;
    children?: React.ReactNode;
    tag?: string;
    title?: string;
    listNumShow?: number;
    isBuy?: boolean;
    topicPrice?: number;
    event?: any;
    updateTime?: any;
}

const ImageList: FC<IImageList> = ({ event, list, idkey, idValue, imgUrlkey, channel, reset, is300, children, tag, listNumShow, isBuy, topicPrice, title,updateTime }) => {
    const [newImgList, setNewImgList] = useState<any>([])
    const [config] = useConfBase();
    const [hoverItem, setHoverItem] = useState<number>(-1)
    const [dataList, setDataList] = useState<any[]>([]);
    const [encryptUrls, setEncryptUrls] = useState<string[]>([])
    const [listShow, setListShow] = useState<number>(30)

    const memoList = useMemo(() => {
        return Array.isArray(list) ? list.map((x) => ({ ...x, name: x[idkey], value: x[idValue] })) : []
    }, [list, idkey, idValue,])

    useEffect(() => {
        if (Array.isArray(list) && list.length > 0) {
            const _l = list.map((x) => ({ ...x, name: x[idkey], value: x[idValue] }))
            setDataList(_l)
        }
    }, [list, idkey, idValue,])

    const fmtimg = useCallback(() => {
        if (Array.isArray(dataList) && config?.image_url) {
            const _l = window.location.href
            if (_l.indexOf("main") < 0) {
                cancelAllPreviousFetchRequests()
            }
            if (["vip", "cili", "shipin", "tupian", "meinv", "xiaoshuo", "yousheng", "nvyou", "tese", "remen","topic","remen2","remen3"].includes(channel)) {
                let _size = '?size=208x130'
                if (["vip", "shipin", "cili", "tese", "remen","topic","remen2","remen3"].includes(channel)) {
                    _size = u.isMobile() ? '?size=400x225' : '?size=500x281'
                }
                if (["tupian"].includes(channel)) {
                    _size = u.isMobile() ? '?size=400x225' : '?size=400x225'
                }
                if (["meinv"].includes(channel)) {
                    _size = u.isMobile() ? '?size=400x225' : '?size=400x225'
                }
                if (["nvyou"].includes(channel)) {
                    _size = u.isMobile() ? '?size=400x225' : '?size=400x225'
                }
                if (["yousheng"].includes(channel)) {
                    _size = u.isMobile() ? '?size=70x100' : '?size=110x150'
                }
                //const mulImgs = [config?.image_url, "https://m.shengzhongc.cn", "https://mm.shengzhongc.cn", "https://mmm.shengzhongc.cn"]
                //const randomIdx = Math.floor(Math.random() * mulImgs.length);
                const imgs = dataList.map((x) => `${config?.image_url}/${x[imgUrlkey]}.txt${_size}`)
                console.log('old img -> ', imgs);

                // let vidKeyParam = u.addVidKeyParam();
                let vidKeyParam = '';
                const res = dataList.map((x) => `${config?.image_url}/${x[imgUrlkey]}${vidKeyParam}`)
                console.log('new img -> ', res);
                setEncryptUrls(res)
                setListShow(listNumShow?listNumShow:dataList.length)
                // await u.fetchInBatches(res, setNewImgList)
            }
        }
    }, [config, channel, imgUrlkey, dataList])

    useLayoutEffect(() => {
        fmtimg()
    }, [fmtimg])

    useEffect(() => {
        if (reset) {
            setNewImgList([])
            setEncryptUrls([])
            fmtimg();
        }
    }, [reset])

    // useEffect(() => {
    //     console.log(encryptUrls, '11')
    // }, [encryptUrls])


    const memoClassName = useMemo(() => {
        if (channel === "xiaoshuo") {
            return styles.xs_con
        }
        if (channel === "yousheng") {
            return styles.ys_con
        }
        if (channel === "tese" || channel === "topic") {
            return styles.ts_con
        }
        return styles.img_con
    }, [channel])

    const memoContainerName = useCallback((val: any) => {
        if (u.isMobile()) {
            return 'i_cvr'
        }
        if (val?.isLs === 0) {
            return ""
        }
        if (["中文字幕", "成人动漫"].includes(val?.tags)) {
            return ""
        }
        if (val?.thumb?.includes("nvyou")) {
            return ""
        }
        if (channel === "nvyou") {
            return ""
        }
        if (channel === "tupian") {
            return ""
        }
        if (channel === "meinv") {
            return ""
        }
        return `i_cvr`
    }, [channel])
    const [flipText, flipHander] = useFlipFlop("登录", "登录中...")
    const handleCategoryClick = useCallback((item: any, isFreeView: boolean) => {
        const _l = window.location.href
        localStorage.setItem("j", window.location.href);
        if ((channel === "vip" || channel === "remen") && !u.isVip()) {
            return window.open("/user/buy")
        }
        if ((channel === "vip" || channel === "remen") && u.isVip() && u.isLogin()) {
            return window.open(`/s/video/${channel}/${item.id}`)
        }
        const isTrue = ["cili"].includes(channel);
        if (isTrue) {
            return window.open(`/s/detail/${channel}/${item.id}`)
        }
        if (["tupian", "xiaoshuo", "yousheng"].includes(channel)) {
            let key = channel === "tupian" ? "cat_id" : "cid"
            return window.open(`/s/chapter/${channel}/${item[key]}/${item.id}`)
        }
        if (channel === "shipin" || channel === "remen" || channel === "remen2" || channel === "remen3") {
            const isNvyou = item.thumb.includes("nvyou");
            if (isNvyou) {
                return window.open(`/page/nvyou/${item.id}`)
            }
            return window.open(`/s/video/${channel}/${item.id}`)
        }
        if (channel === "meinv") {
            if (_l.indexOf("detail/meinv") >= 0) {
                return window.open(`/s/chapter/${channel}/${item.cat_id}/${item.id}`)
            }
            return window.open(`/s/detail/${channel}/${item.id}`)
        }

        if (channel === "nvyou") {
            return window.open(`/s/video/${channel}/${item.id}`)
        }

        if (channel === "tese") {
            localStorage.setItem(ELocalKey._TESE, u.encrypt(JSON.stringify(item?._info)))
            return window.open(`/s/video/${channel}/${item.id}`)
        }

        if (channel === "topic") {
            localStorage.setItem(ELocalKey._TESE, u.encrypt(JSON.stringify(item?._info)))
            if(isBuy || isFreeView){
                return window.open(`/s/video/${channel}/${item.id}`)
            }else{
                event(topicPrice)
                // modalSubject$.next({
                //     id: EModalTypes.BUYTOPIC,
                //     data: {price: topicPrice },
                //     event: () => flipHander(0)
                // })
            }
        }
    }, [])
    

    return (
        <div>
            <div className={memoClassName}>
                {Array.isArray(dataList) && dataList.slice(0, listShow).map((x: any, i: any) => {
                    return <div key={i} className={`${styles.list_item}`} onMouseEnter={() => {(isBuy || x.is_free) ?setHoverItem(i):setHoverItem(-1);}}>
                        <div className={`${styles.title} to`} style={{height: 'auto', maxHeight: '43px', lineHeight: '1.3rem', paddingBottom: '8px',fontSize:'16px'}}>{i+1}. {x.is_free && <span className="pink_badge" style={{marginRight: '3px'}}>免费试看</span>}{x?.name || x.title}</div>
                        <div className={styles.wrapper}>
                            <div className={`${styles.img_cover} relative`} onClick={() => handleCategoryClick(x, x.is_free)}>
                                {!["yousheng", 'xiaoshuo'].includes(channel) && <>
                                <Img reload={reset} encryptUrl={encryptUrls[i]} cls="i_cvr" src={newImgList[i]} >
                                    {(_decryptUrl: any) => {
                                        return <>
                                            {_decryptUrl &&
                                                <Img reload={reset} cls="lazy list" encryptUrl={encryptUrls[i]} style={{ objectFit: memoContainerName(x) ? 'contain' : 'cover', width: "100%", height: "100%" }} src={_decryptUrl} />
                                            }
                                        </>
                                    }}
                                </Img>
                                </>}
                                {tag && <div className={styles.tag}> {tag}</div>}
                                {hoverItem === i && !["tupian", "meinv", "yousheng", 'xiaoshuo'].includes(channel) && <div className={styles.shadow}>
                                    <Img src={appendHost("/images/play/play.png")} width={40} height={40} />
                                    <div>
                                    </div>
                                </div>}
                                {x.duration && <div className={styles.vid_duration}>
                                    <div>{u.secondsToHms(x?.duration)}</div>
                                </div>}
                                {channel !== "xiaoshuo" && channel !== "yousheng" && <div className={styles.vid_time}>
                                  <div>
                                    {!!updateTime && <>
                                        {u.fmtDateSince(updateTime * 1000)}
                                    </>}
                                    {!(updateTime) && <>
                                        {x?.update_time ? u.fmtDateSince(x?.update_time * 1000) : x?.insert_time ? u.fmtDateSince(x?.insert_time * 1000) : ''}
                                    </>}
                                    </div>
                                </div>}
                            </div>
                            <div className={`${styles.content_cover}`} >
                                <div style={{whiteSpace: "pre-wrap"}} dangerouslySetInnerHTML={{ __html:x.description}}></div>
                            </div>
                        </div>
                        <div className={styles.line}></div>
                        <div>{children}</div>
                    </div>
                })}
            </div>
            {memoList.length === 0 && <Empty />}
            {/* {<BuyTopicModal />} */}
        </div>
    )
}
export default ImageList